
import { defineComponent, ref } from 'vue';
import { horizontal, vertical } from '@/components/common/nav/index';
import '@/assets/less/index.less';
import textPopover from '@/components/common/text-popover/index.vue';
import selectList from '@/components/common/select-list/index.vue';
import passwordDialog from '@/components/view/common/dialog/password-change';
import { superBaseRouter, superSubRouter } from '@/router/data';
import setTimeZoneDialog from '@/components/view/common/dialog/time-change';
import goBackLogin from '@/router/back-login';
import setLang from '@/methods/setLang';
import { controlPop } from './util';

const menus = [{
    label: WordList.RDeivicePersonalDevice,
    name: `${superBaseRouter}_${superSubRouter.personalDevice}|${superBaseRouter}_${superSubRouter.personalDeviceDetail}`,
    path: `/${superBaseRouter}/${superSubRouter.personalDevice}`,
    icon: 'el-icon-my-devices'
}, {
    label: WordList.ProperAllTextProjectDevice,
    name: `${superBaseRouter}_${superSubRouter.projectDevice}|${superBaseRouter}_${superSubRouter.communityDeviceDetail}`,
    path: `/${superBaseRouter}/${superSubRouter.projectDevice}`,
    icon: 'el-icon-my-comunity-device'
}, {
    label: WordList.ProperAllTextProject,
    name: `${superBaseRouter}_${superSubRouter.project}`,
    path: `/${superBaseRouter}/${superSubRouter.project}`,
    icon: 'el-icon-my-community'
}, {
    label: WordList.TabelPersonKeyInHtmlUser,
    name: `${superBaseRouter}_${superSubRouter.user}|${superBaseRouter}_${superSubRouter.userInfo}|${superBaseRouter}_${superSubRouter.macLibrary}|${
        superBaseRouter}_${superSubRouter.officeInfo}|${superBaseRouter}_${superSubRouter.propertyInfo}`,
    path: `/${superBaseRouter}/${superSubRouter.user}`,
    icon: 'el-icon-my-user'
}, {
    label: WordList.ProperAllTextFeaturePlan,
    name: `${superBaseRouter}_${superSubRouter.featurePlan}`,
    path: `/${superBaseRouter}/${superSubRouter.featurePlan}`,
    icon: 'el-icon-my-user'
}, {
    label: WordList.NavInHtmlMenuFirmware,
    name: `${superBaseRouter}_${superSubRouter.firmware}`,
    path: `/${superBaseRouter}/${superSubRouter.firmware}`,
    icon: 'el-icon-my-firmware'
}, {
    label: WordList.NavInHtmlMenuVersionManage,
    name: `${superBaseRouter}_${superSubRouter.model}`,
    path: `/${superBaseRouter}/${superSubRouter.model}`,
    icon: 'el-icon-my-model-manager'
}, {
    label: WordList.ProperAllTextCustomerService,
    name: `${superBaseRouter}_${superSubRouter.customerService}`,
    path: `/${superBaseRouter}/${superSubRouter.customerService}`,
    icon: 'el-icon-my-support'
}, {
    label: WordList.NavInHtmlMenuOrder,
    name: `${superBaseRouter}_${superSubRouter.payments}|${superBaseRouter}_${superSubRouter.paymentInfo}|${
        superBaseRouter}_${superSubRouter.exportOrder}`,
    path: `/${superBaseRouter}/${superSubRouter.payments}`,
    icon: 'el-icon-my-order'
}, {
    label: WordList.ProperAllTextAuditLogs,
    name: `${superBaseRouter}_${superSubRouter.auditLogs}`,
    path: `/${superBaseRouter}/${superSubRouter.auditLogs}`,
    icon: 'el-icon-my-intercomlogs'
}];

const userOperaList = [{
    label: WordList.TabelChangePwTitle,
    key: 'password'
}, {
    label: WordList.TabelCommunitiesTimeZone,
    key: 'timeZone'
}];

export default defineComponent({
    components: {
        horizontal,
        vertical,
        textPopover,
        selectList,
        passwordDialog,
        setTimeZoneDialog
    },
    setup() {
        const {
            controlPopVal,
            hideAllPop,
            hideOtherPop
        } = controlPop();
        setLang();
        const isShowTimeZoneDialog = ref(false);
        const isShowPasswordDialog = ref(false);
        const chooseOpera = (key: string) => {
            hideAllPop();
            if (key === 'password') {
                isShowPasswordDialog.value = true;
            } else {
                isShowTimeZoneDialog.value = true;
            }
        };

        return {
            menus,
            userOperaList,
            chooseOpera,
            isShowPasswordDialog,
            isShowTimeZoneDialog,
            goBackLogin,
            superBaseRouter,
            superSubRouter,
            controlPopVal,
            hideOtherPop
        };
    }
});

import { ref, Ref } from 'vue';

interface ControlType {
    opera: boolean;
}
const controlPop = () => {
    const controlPopVal: Ref< ControlType > = ref({
        opera: false
    });
    const hideAllPop = () => {
        controlPopVal.value.opera = false;
    };
    const hideOtherPop = (event: boolean, type: keyof ControlType) => {
        controlPopVal.value[type] = event;
    };
    return {
        hideAllPop,
        controlPopVal,
        hideOtherPop
    };
};

export default null;
export {
    controlPop
};